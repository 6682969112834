import React, { useEffect } from 'react';
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { requestResetPassword } from '../../actions/auth';
import * as Yup from "yup";
import LockIcon from '@mui/icons-material/Lock';
import rutas from '../../routes/constants/routes';

const ResetPassword = (props) => {

  const docType = props.docType;
  
  const formik = useFormik({
      initialValues: {
        identificationType: 1,
        identification: "",
        email: ""
      },
      validationSchema: Yup.object({
        identificationType: Yup.number().required("Ingrese una opción"),
        identification: Yup.string().required("Ingrese un número válido"),
        email: Yup.string().required("El email es obligatorio")
      }),
        onSubmit: (data) => {
            props.requestResetPassword(data);
        }
  })

  return (
    <>
      <div>
        <div className="d-grid gap-2 col-12 col-md-5 mx-auto" style={{paddingTop:"2rem", paddingBottom:"4rem"}}> 

          <div className="container">
            <div className="d-flex flex-row bd-highlight" style={{justifyContent:"center"}}>
              <div className="mb-3" >
              <div style={{height: '3.438rem', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"3rem" }}>
                        <div style={{width: "45px", height: "45px", background: "#E3000B", borderRadius: "100px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <LockIcon style={{fill: "white"}}></LockIcon>
                        </div>
                    </div>
                    <h5 className="text-center" style={{marginTop:"1rem",marginBottom:"1.875rem"}}>Recuperar Contraseña</h5>
              </div>
            </div>
          </div>

          <form className="container" style={{marginBottom:"2.5rem"}} onSubmit={formik.handleSubmit}>
                <div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label" style={{color:"gray",fontWeight:600}}>Email</label>
                      <input type="email" onChange={formik.handleChange} error={formik.errors.email} name="email" id="email" 
                          className="form-control" style={{borderColor:"black",padding:"0.625rem"}}></input>
                      {formik.touched.email && formik.errors.email ? (
                              <div className='text-danger'>{formik.errors.email}</div>
                              ): null}
                    </div>
                    <div className="mb-4 row">
                    <div className="col-12 col-md-4" style={{whiteSpace: "nowrap"}}>
                        <label className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem", textAlign: 'right', clear: 'both', float:'left'}}>Tipo documento</label>
                        <div className="dropdown" style={{width: "6.875rem"}}>
                            <select className="form-select" onChange={formik.handleChange} error={formik.errors.identificationType} name="identificationType" style={{borderColor:"black",fontWeight: "bold", height: "2.5rem"}}>
                                {docType?.map((element, index) => {return <option key={index} value={element.id}>{element.name}</option>})}
                            </select>
                        </div>
                        {formik.touched.identificationType && formik.errors.identificationType ? (
                            <div className='text-danger'>{formik.errors.identificationType}</div>
                            ): null}
                    </div>
                    <div className="col-12 col-md-8" style={{whiteSpace: "nowrap"}}>
                        <label htmlFor="identification" className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem", clear: 'both', float:'left'}}>Número de documento<span style={{fontWeight: "lighter"}}>(Sin puntos ni guiones)</span> </label>
                        <input name="identification" className="form-control" id="identification" style={{borderColor:"black", height: "2.5rem"}} onChange={formik.handleChange} error={formik.errors.identification}></input>
                        {formik.touched.identification && formik.errors.identification ? (
                            <div className='text-danger'>{formik.errors.identification}</div>
                        ): null}
                    </div>
                </div>
                <div className="mb-3">
                </div>
                <div style={{maxWidth:"100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className={`btn btn-danger ${props.loading ? "disabled" : ""}`} style={{backgroundColor: "#E3000B", padding: "0.500rem 0.625rem", margin:"1rem 0rem", width:"100%"}} type="submit">Recuperar contraseña</button>
                </div>
                {
                  props?.successPasswordReset ?
                  <div class="alert alert-info" role="alert">
                      Por favor verifique su casilla de email para recuperar la contraseña
                  </div>
                :
                  ""
                }
                {
                  props?.errorPasswordReset ?
                  <div class="alert alert-danger" role="alert">
                      { props.error.response.data.message }
                  </div>
                :
                  ""
                }
                  
            </div>
          </form>
          
        </div>
      </div>
    </>
  );
}

export default connect(
  state => ({
    docType: state.params.docType,
    successPasswordReset: state.auth.successPasswordReset,
    loading: state.auth.loading,
    errorPasswordReset: state.auth.errorPasswordReset,
    error: state.auth.error
  }),
  dispatch => ({
    requestResetPassword: (data) => dispatch(requestResetPassword(data))
  })
)(ResetPassword);