import React from 'react';
import { connect } from 'react-redux';
import { useEffect } from "react";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { login, flag } from "../../actions/auth";
import * as Yup from "yup";
import TokenService from '../../services/token';
import rutas from '../../routes/constants/routes';
import LockIcon from '@mui/icons-material/Lock';
import ReCAPTCHA from "react-google-recaptcha";

const { REACT_APP_CAPTCHA_APIKEY } = process.env;

const Login = (props) => {
    useEffect(() => {
        if (props.success) {
            if (TokenService.hasSession()) {
                window.location.assign(rutas.DASHBOARD)
            } 
        }
        if (TokenService.hasSession()) {
            window.location.assign(rutas.DASHBOARD)
        }
    }, [props]) 

    const docType = props.docType;
    const formik = useFormik({
        initialValues: {
            identificationType: "1",
            identification: "",
            email: "",
            password: "",
            captchaToken: ""
        },
        validationSchema: Yup.object({
            identification: Yup.string()
                .required("El número de documento es obligatorio")
                .matches(/^\d+$/, "Debe ingresar un número de documento sin puntos ni guiones"),
            password: Yup.string().required("Ingrese su contraseña"),
            email: Yup.string()
            .required("El email es obligatorio"),
            captchaToken: Yup.string().required("Captcha requerido"),
            identificationType: Yup.number().required("Ingrese una opción")
        }),
        onSubmit: (formData) => {
            props.login(formData);
        }
    })

    return (
        <>
        <div>
            <div className="d-grid gap-2 col-12 col-md-4 mx-auto">
                <form className="container" style={{marginBottom:"2.5rem"}} onSubmit={formik.handleSubmit}>
                    <div>
                        <div style={{height: '3.438rem', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"3rem" }}>
                            <div style={{width: "45px", height: "45px", background: "#E3000B", borderRadius: "100px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <LockIcon style={{fill: "white"}}></LockIcon>
                            </div>
                        </div>
                        <h5 className="text-center" style={{marginTop:"1rem",marginBottom:"1.875rem"}}>Complete sus datos para iniciar sesión</h5>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label" style={{color:"gray",fontWeight:600}}>Email</label>
                            <input type="email" onChange={formik.handleChange} error={formik.errors.email} name="email" id="email" 
                                className="form-control" style={{borderColor:"black",padding:"0.625rem"}}></input>
                            {formik.touched.email && formik.errors.email ? (
                                    <div className='text-danger'>{formik.errors.email}</div>
                                    ): null}
                        </div>
                        <div className="mb-4 row">
                            <div className="col-12 col-md-4" style={{whiteSpace: "nowrap"}}>
                                <label className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem", textAlign: 'right', clear: 'both', float:'left'}}>Tipo documento</label>
                                <div className="dropdown" style={{width: "6.875rem"}}>
                                    <select className="form-select" onChange={formik.handleChange} error={formik.errors.identificationType} name="identificationType" style={{borderColor:"black",fontWeight: "bold", height: "2.5rem"}}>
                                        {docType?.map((element, index) => {return <option key={index} value={element.id}>{element.name}</option>})}
                                    </select>
                                </div>
                                {formik.touched.identificationType && formik.errors.identificationType ? (
                                    <div className='text-danger'>{formik.errors.identificationType}</div>
                                    ): null}
                            </div>
                            <div className="col-12 col-md-8" style={{whiteSpace: "nowrap"}}>
                                <label htmlFor="identification" className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem", clear: 'both', float:'left'}}>Número de documento <span style={{fontWeight: "lighter"}}>(Sin puntos ni guiones)</span> </label>
                                <input name="identification" className="form-control" id="identification" style={{borderColor:"black", height: "2.5rem"}} onChange={formik.handleChange} error={formik.errors.identification}></input>
                                {formik.touched.identification && formik.errors.identification ? (
                                    <div className='text-danger'>{formik.errors.identification}</div>
                                ): null}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label" style={{color:"gray",fontWeight:600}}>Contraseña</label>
                            <input type="password" onChange={formik.handleChange} error={formik.errors.password} name="password" id="password" 
                                className="form-control" style={{borderColor:"black",padding:"0.625rem"}}></input>
                            {formik.touched.password && formik.errors.password ? (
                                    <div className='text-danger'>{formik.errors.password}</div>
                                    ): null}
                        </div>
                        <div className="mb-12">
                            <ReCAPTCHA
                                sitekey={REACT_APP_CAPTCHA_APIKEY}
                                error={formik.errors.captchaToken}
                                style={{margin: "0 auto",
                                    display: "table"}}
                                name="captchaToken" 
                                id="captchaToken"
                                onChange={(value) => {
                                    formik.setFieldValue("captchaToken", value);
                                    formik.setSubmitting(false);
                                    }
                                }
                            />
                        
                        </div>
                        {
                            props.error ?
                            <div class="alert alert-danger"  style={{maxWidth:"100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}} role="alert">
                                { props.error.response.data }
                            </div>
                            : ""
                        }
                        <div style={{maxWidth:"100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <button className={`btn btn-danger ${props.loading ? "disabled" : ""}`} style={{backgroundColor: "#E3000B", padding: "0.500rem 0.625rem", margin:"1rem 0rem", width:"100%"}} type="submit">Iniciar sesión</button>
                        </div>
                        <Link to={rutas.RESET_PASWORD} style={{borderColor:"black"}}>Recuperar contraseña</Link>
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}

export default connect(
    state => ({
      session: state.auth.session,
      success: state.auth.success,
      loading: state.auth.loading,
      data: state.auth.data,
      docType: state.params.docType,
      error: state.auth.error
    }),
    dispatch => ({
      login: (loginData) => dispatch(login(loginData)),
      flag: () => dispatch(flag())
    })
  )(Login);